import React from 'react';
import { graphql } from 'gatsby';
import { isDefined, isArrayLike } from 'typical';
import PageMeta from 'src/components/PageMeta';
import getComponentByType from 'src/utils/getComponentByType';

const Page = ({ components, meta }) => {
  return (
    <>
      {meta && <PageMeta {...meta} />}
      {components &&
        components.map((component, index) => {
          const { __typename } = component;

          const Module = getComponentByType(__typename);

          const prevComponent = index > 0 ? components[index - 1] : undefined;
          const shouldCollapseMargin =
            isDefined(prevComponent) &&
            isDefined(prevComponent.colorMode) &&
            isDefined(component.colorMode) &&
            prevComponent.colorMode === component.colorMode &&
            /* eslint-disable */
            prevComponent.background == component.background;
          /* eslint-enable */

          const isHero = index === 0;

          const slugList = component.slug
            ? component.slug.split('#')
            : undefined;
          const elementId =
            isArrayLike(slugList) && slugList.length >= 2
              ? slugList.pop()
              : undefined;

          if (Module) {
            return (
              <Module
                key={component.id}
                isHero={isHero}
                shouldCollapseMargin={shouldCollapseMargin}
                elementId={elementId}
                {...component}
              />
            );
          }

          return <div key={component.id}>{__typename}</div>;
        })}
    </>
  );
};

export const query = graphql`
  fragment PageTemplateFragment on ContentfulPageTemplate {
    meta {
      ...PageMetaFragment
    }
    components {
      ... on ContentfulLogoListModule {
        ...LogoListModuleFragment
      }
      ... on ContentfulThreeUpModule {
        ...ThreeUpModuleFragment
      }
      ... on ContentfulNewTwoUpModule {
        ...NewTwoUpModuleFragment
      }
      ... on ContentfulFeaturedArticlesModule {
        ...FeaturedArticlesModuleFragment
      }
      ... on ContentfulSliderModule {
        ...SliderModuleFragment
      }
      ... on ContentfulCustomModule {
        ...CustomModuleFragment
      }
      ... on ContentfulColumnModule {
        ...ColumnModuleFragment
      }
      ... on ContentfulRevealModule {
        ...RevealModuleFragment
      }
      ... on ContentfulZoomModule {
        ...ZoomModuleFragment
      }
      ... on ContentfulCaptionsModule {
        ...CaptionsModuleFragment
      }
      ... on ContentfulGridModule {
        ...GridModuleFragment
      }
      ... on ContentfulCardStackModule {
        ...CardStackModuleFragment
      }
      ... on ContentfulStatsModule {
        ...StatsModuleFragment
      }
      ... on ContentfulAccordionModule {
        ...AccordionModuleFragment
      }
      ... on ContentfulWebinarModule {
        ...WebinarModuleFragment
      }
    }
  }
`;

export default Page;
