import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { trackPageView } from 'src/utils/analytics';

import Layout from 'src/components/Layout';
import Page from 'src/components/Page';

const PageTemplate = ({ data }) => {
  const {
    isNavTransparent,
    removeLayout,
    nav,
    stickyBannerForm,
  } = data.contentfulPageTemplate;

  useEffect(() => trackPageView(), []);

  return (
    <Layout
      removeLayout={removeLayout}
      isNavTransparent={isNavTransparent}
      nav={nav}
      stickyBannerForm={stickyBannerForm}
    >
      <Page {...data.contentfulPageTemplate} />
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPageTemplate(slug: { eq: $slug }) {
      removeLayout
      isNavTransparent
      nav {
        ...NavFragment
      }
      stickyBannerForm {
        ...FormFragment
      }
      ...PageTemplateFragment
    }
  }
`;
